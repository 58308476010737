@import '../../../variables.sass'
    
.inputgroup-addon-edit-service
    width: 4.75em

.inputtext-edit-service
    width: 14em

.inputgroup-addon-edit-service-length
    border-bottom-left-radius: 0 !important

.inputtext-edit-service-length
    border-bottom-right-radius: 0 !important

.length-slider
    width: 262.5px

.inputswitch-group
    width: 262.5px
    height: 26.5px
    background-color: $backgorundLightGrey
    border: 1px solid $borderGrey
    border-radius: 20px
    label
        color: $fontGrey
        padding: 1px
        padding-left: 0.75em
    div
        float: right