$primaryColor: #007ad9
$backgorundLightGrey: #eaeaea
$fontGrey: #848484
$borderGrey: #a6a6a6
$buttonSecondary: #f4f4f4
$buttonSecondaryFont: #333333

$break-xs: 500px
$break-sm: 576px
$break-md: 768px
$break-lg: 992px
$break-xl: 1440px
$break-xxl: 1900px
$break-xxxl: 2500px
