@import "../../variables.sass"

.list-heading
    margin-left: 0.75em
    margin-right: 0.75em
    margin-bottom: 0.5em
    color: $buttonSecondaryFont
    border-bottom: 2px solid $borderGrey

.services-list
    list-style-type: none
    padding-top: 0
    display: grid
    grid-gap: 1em
    justify-content: center
    grid-template-columns: 85%
    margin-bottom: 1.5em

    @media screen and (min-width: $break-lg)
        grid-template-columns: repeat(2, 29.5em)

    @media screen and (min-width: $break-xl)
        grid-template-columns: repeat(3, 29.5em)

    @media screen and (min-width: $break-xxl)
        grid-template-columns: repeat(4, 28.5em)

    @media screen and (min-width: $break-xxxl)
        grid-template-columns: repeat(5, 30.6em)

.p-dialog
    overflow: auto
    max-height: 100%
