@import '../../../../variables.sass'

.pickList-container
    display: inline-flex

.picklist-blacklist
    padding-left: 29.25px

.pickList-header
    width: 6em
    border: 1px solid $borderGrey
    border-top-right-radius: 3px
    border-top-left-radius: 3px
    background-color: $backgorundLightGrey
    text-align: center
    color: $fontGrey

.pickList-body
    width: 6em
    height: 9em
    overflow: auto
    border: 1px solid $borderGrey
    border-top: 0
    border-bottom-right-radius: 3px
    border-bottom-left-radius: 3px

.pickList-list
    list-style-type: none
    padding-left: 0

.picklist-item
    text-align: center

.picklist-item:hover
    background-color: $backgorundLightGrey
    cursor: pointer

.pickList-item-highlight
    background-color: $primaryColor
    color: white
    
.pickList-item-highlight:hover
    background-color: $primaryColor

.pickList-button-container
    display: inline-grid
    padding-top: 10px
    padding-left: 29.25px

.pickList-button
    height: 24px
    width: 36px
