.service-card

    .service-card-account
        opacity: .7
        margin-bottom: .5em
        margin-top: -.25em
        font-weight: bold

    .service-card-account-hidden
        opacity: 0
        cursor: default

    .service-card-button-container
        margin-top: .5em
        .button-standard
            margin-right: 0.25em
            

    .button-favorite
        float: right
        margin-right: 0em

.service-password-input-text
    width: 100%