@import "../../variables.sass"

.header
    background-color: $primaryColor
    padding: .25em
    // position: fixed
    // top: 0
    // width: 100%

.header-row
    margin-left: 0
    margin-right: 0
