@import '../../variables.sass'

.footer
    position: absolute
    bottom: 0
    width: 100%
    height: 30px
    background-color: #f5f5f5

    p
        margin: 0
